import React, { useState, useEffect } from "react"
import { useTheme } from "@material-ui/core/styles"
import MobileStepper from "@material-ui/core/MobileStepper"
import Button from "@material-ui/core/Button"
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace"
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight"
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import { fadeInLeft } from "react-animations"

import new01 from "../images/new01.jpg"
import new02 from "../images/new02.jpg"
import new03 from "../images/new03.jpg"
import new04 from "../images/new04.jpg"
import new05 from "../images/new05.jpg"

import { colors, boxShadow } from "./globalStyle"

import styled, { keyframes } from "styled-components"

import GlobalHeading from "./globalHeading"

const fadeInLeftAnimation = keyframes`${fadeInLeft}`
const FadeDiv = styled.div`
  animation: 0.3s ${fadeInLeftAnimation};
`
const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const StyledDiv = styled.div`
  .MuiMobileStepper-dot {
    background-color: ${colors.primary};
    opacity: 0.35;
  }
  .MuiMobileStepper-dotActive {
    background-color: ${colors.primary};
    opacity: 1;
  }
  .MuiMobileStepper-root {
    background: white;
  }
`

const Slider = ({ siteTitle }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener("resize", updateWindowDimensions)

    return () => {
      window.removeEventListener("resize", updateWindowDimensions)
    }
  })

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
  }

  const tutorialSteps = [
    {
      label: "",
      imgPath: new01,
    },

    {
      label: "",
      imgPath: new02,
    },

    {
      label: "",
      imgPath: new03,
    },

    {
      label: "",
      imgPath: new04,
    },

    {
      label: "",
      imgPath: new05,
    },
  ]
  const maxSteps = tutorialSteps.length
  const theme = useTheme()

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  return (
    <StyledDiv activeStep={activeStep}>
      <section>
        {width >= 940 && (
          <main>
            <Button onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardBackspace />
            </Button>
            {/**
          <button>
            <img src={arrow} />
          </button> */}

            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {tutorialSteps.map((step, index) => (
                <div key={step.label} className="stepper-label">
                  {Math.abs(activeStep - index) <= 2 ? (
                    <p>{step.label}</p>
                  ) : null}
                </div>
              ))}
            </SwipeableViews>
            <Button onClick={handleNext} disabled={activeStep === maxSteps - 1}>
              <KeyboardBackspace style={{ transform: "rotate(180deg)" }} />
            </Button>
          </main>
        )}

        {width < 940 && (
          <div className="mobile-slider">
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {tutorialSteps.map((step, index) => (
                <div key={step.label}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <img src={step.imgPath} alt={step.label} />
                  ) : null}
                </div>
              ))}
            </SwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                </Button>
              }
            />
          </div>
        )}
      </section>
    </StyledDiv>
  )
}

Slider.propTypes = {}

Slider.defaultProps = {}

export default Slider
