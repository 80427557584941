import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import GlobalHeading from './globalHeading';
import NetlifyForm from 'react-netlify-form';

import { colors, boxShadow } from './globalStyle';

const StyledForm = styled.form`
  .aditional-msg {
    color: ${colors.blueGrey};
    text-align: left;
    font-family: Maven Pro;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 0;
    margin: 1rem 0;
  }
  main {
    margin-top: 2rem;

    .MuiFormLabel-root {
      font-family: Maven Pro;
      font-size: 0.8rem;
      font-weight: 500;
    }
    .MuiInputBase-root {
      margin: 1.5rem 0;
    }

    width: 60vw;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    div {
      width: 100%;
      margin-bottom: 1rem;

      input {
        font-family: Maven Pro;
      }
      .MuiInputLabel-shrink {
        transform: translate(0, 1.5px) scale(1);
      }
      .MuiInput-underline::after {
        border-bottom: 2px solid ${colors.primary};
      }
      .MuiFormLabel-root.Mui-focused {
        color: ${colors.primary};
      }
      .MuiFormLabel-root.Mui-error {
        color: ${colors.primary};
      }
      p {
        font-size: 0.6rem;
        font-family: Maven Pro;
        margin: 0;
        text-align: right;
        margin-top: -10px;
        color: ${colors.error};
      }
    }
    .button-section {
      margin: 1rem 0rem;
      button {
        cursor: pointer;
        background: ${colors.primary};
        border: 0;
        margin: 0.7rem;
        margin-left: 0;
        border-radius: 31px;
        color: ${colors.white};
        font-family: Maven Pro;
        text-transform: uppercase !important;
        font-size: 0.7rem;
        letter-spacing: 0.07rem;
        padding: 0.45rem 1.5rem;
        box-shadow: ${boxShadow.small};
        text-transform: none;
      }
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: ${colors.primary};
      &:hover {
        background-color: rgba(208, 174, 140, 0.08);
      }
    }
    .MuiIconButton-colorSecondary {
      &:hover {
        background-color: rgba(208, 174, 140, 0.08);
      }
    }
    .MuiFormControlLabel-label {
      font-family: Maven Pro;
    }
    .agree-section {
      display: flex;
      align-items: center;
      margin: 2rem 0rem;
      margin-left: 0.5rem;

      label {
        margin-right: 0.25rem;
        span {
          font-size: 0.8rem;
          color: rgba(0, 0, 0, 0.54);
        }
        .MuiIconButton-label {
          color: ${colors.primary};
        }
      }
      h4 {
        margin: 0;
        font-size: 0.8rem;
        color: ${colors.primary};
        padding: 2px;
        border-bottom: 1px solid currentColor;
        cursor: pointer;
      }
    }
    .error-msg {
      font-size: 0.7rem;
      font-family: Maven Pro;
      margin: 0;
      text-align: left;
      margin-top: 10px;
      line-height: 0.65rem;
      color: ${colors.error};
    }
    .submit {
      margin: 1rem 0rem;
      button {
        cursor: pointer;
        color: ${colors.white};
        background: ${colors.primary};
        padding: 0.7rem 1.6rem;
        font-family: Maven Pro;
        font-size: 0.9rem;
        font-weight: bold;
        letter-spacing: 0.09rem;
        border: 0;
        border-radius: 31px;
        box-shadow: ${boxShadow.small};
      }
    }
  }

  .four-arrows {
    height: 90vh;
    position: absolute;
    right: 0;
    margin-top: -15rem;
    z-index: 0;
  }

  @media (max-width: 850px) {
    .submit {
      button {
        padding: 0.5rem 1.2rem !important;
        font-size: 0.7rem !important;
      }
    }
  }
  @media (max-width: 768px) {
    .agree-section {
      width: 90vw !important;
      max-width: 90vw !important;
    }
  }
  @media (max-width: 550px) {
    .four-arrows {
      display: none;
    }

    // .submit {
    //   button {
    //     padding: 0.4rem 1rem !important;
    //     font-size: 0.6rem !important;
    //   }
    // }
  }
  @media (max-width: 420px) {
    background-position: -5vw -5vh !important;
    background-size: 180vw !important;
    .agree-section {
      width: 60vw !important;
      max-width: 60vw !important;
      display: flex;
      flex-direction: column !important;
      align-items: flex-start !important;
      align-self: flex-start !important;
      margin: 0 !important;
      margin-bottom: 1rem !important;
    }
  }
`;

const SmallBtn = styled(Button)`
  color: ${props =>
    !props.checkedbtn ? `${colors.classicGrey} !important` : `${colors.white}`};
  background: ${props =>
    !props.checkedbtn ? `${colors.white} !important` : `${colors.primary}`};
  box-shadow: ${props =>
    !props.checkedbtn ? `${boxShadow.big} !important` : `${boxShadow.small}`};
`;

export default function BasicTextFields() {
  const [interestedAreas, setInterestedAreas] = useState({});
  const [textbox, setTextbox] = useState({});
  const [error, setError] = useState({});
  const [agree, setAgree] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [disabled, setDisabled] = useState(true);

  const checkAndSend = () => {
    let valid = {};
    let validArr = [];
    setOfFields.map(field => {
      if (!Object.keys(textbox).includes(field) || textbox[field].length == 0) {
        valid[field] = true;
        validArr.push(field);
      } else {
        valid[field] = false;
      }
    });
    setError(valid);
    if (validArr.length === 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
    if (!agree) {
      setErrorMsg('Zabudli ste označiť súhlas so spracovaním osobných údajov');
      setDisabled(true);
    }
  };
  const setOfBtns = ['Strihanie', 'Farbenie', 'Iné'];
  const btnSection = setOfBtns.map(btn => (
    <SmallBtn
      key={btn}
      checkedbtn={
        Object.keys(interestedAreas).includes(btn) && interestedAreas[btn]
          ? 1
          : 0
      }
      onClick={() => {
        setInterestedAreas({
          ...interestedAreas,
          [btn]: !interestedAreas[btn],
        });
      }}
    >
      {btn}
    </SmallBtn>
  ));

  const setOfFields = [
    'Meno a Priezvisko',
    'Telefónne číslo',
    'Email',
    'Komentár',
  ];
  const fieldsSection = setOfFields.map(field => (
    <TextField
      helperText={error[field] ? '* Toto pole je povinné' : ''}
      error={error[field]}
      key={field}
      id={field}
      label={field}
      name={field}
      type="text"
      value={textbox[field]}
      onChange={e => {
        setTextbox({ ...textbox, [field]: e.target.value });
        setError({
          ...error,
          [field]: e.target.value.length > 0 ? false : true,
        });
        setErrorMsg('');
      }}
    />
  ));

  return (
    <StyledForm noValidate autoComplete="off">
      <GlobalHeading primary={'Napíšte nám'} />
      <NetlifyForm name="contact-form-crop">
        {({ loading, errorPlugin, success }) => (
          <div>
            {loading && <div className="aditional-msg">Loading...</div>}
            {errorPlugin && (
              <div className="aditional-msg">
                Vaša správa nebola poslaná, skúste to neskôr.
              </div>
            )}
            {success && (
              <div className="aditional-msg">
                Ďakujeme, že ste nás kontaktovali.
              </div>
            )}
            {!loading && !success && (
              <main>
                {fieldsSection}
                <section className="agree-section">
                  {' '}
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="agree"
                        checked={agree}
                        onChange={() => {
                          setAgree(!agree);
                          setErrorMsg('');
                        }}
                        value={agree}
                      />
                    }
                    label="Súhlas so spracovaním "
                  />{' '}
                  <a href="/spracovanie-osobnych-udajov">
                    {' '}
                    <h4>osobných údajov</h4>
                  </a>
                </section>
                <section className="submit">
                  {disabled && (
                    <Button type="button" onClick={checkAndSend}>
                      odoslať
                    </Button>
                  )}
                  {!disabled && <Button type="submit">odoslať</Button>}
                  <p className="MuiFormHelperText-root Mui-error error-msg">
                    {errorMsg}
                  </p>
                </section>
              </main>
            )}
          </div>
        )}
      </NetlifyForm>
    </StyledForm>
  );
}
