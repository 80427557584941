import React, { useState, useEffect } from "react"
import bannerPic from "../images/banner.jpg"

import { colors, boxShadow } from "./globalStyle"
import Scroll from "./scroll"
import styled, { keyframes } from "styled-components"
import { fadeInUp } from "react-animations"
import { Button } from "@material-ui/core"

const fadeInUpAnimation = keyframes`${fadeInUp}`

const FadeDiv = styled.div`
  animation: 0.2s ${fadeInUpAnimation};
`
const StyledImgDiv = styled.div`
  position: relative;
  width: 100%;
  top: 0 !important;
  z-index: 1;
  img {
    position: absolute;

    top: 0;
    height: 90vh !important;
    width: auto !important;
    object-fit: cover;
  }

  @media (max-width: 550px) {
    img {
      height: 80vh !important;
      width: auto !important;
      object-fit: cover;
      margin-bottom: 0 !important;
    }
  }
  @media (max-width: 440px) {
    img {
      height: 70vh !important;
      width: auto !important;
      object-fit: cover;
      margin-bottom: 0 !important;
    }
  }
`

const Landing = styled.div`
  height: calc(100vh - 4.2rem) !important;
  display: flex;
  background-color: ${colors.secondary};

  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;

  section {
    margin-top: calc(100vh * 0.25);

    p {
      position: absolute;
      top: 10rem;
      right: 2rem;
      z-index: 1;
      color: ${colors.blueGrey};
      opacity: 0.4;
      text-align: right;
      font-family: Maven Pro;
      font-size: 0.6rem;
      text-transform: uppercase;
    }
    button {
      cursor: pointer;
      color: ${colors.white};
      background: ${colors.primary};
      padding: 0.7rem 1.6rem;
      font-family: Maven Pro;
      font-size: 0.9rem;
      font-weight: bold;
      letter-spacing: 0.09rem;
      border: 0;
      border-radius: 31px;
      box-shadow: ${boxShadow.small};
      &:hover {
        background: ${colors.primary};
      }
    }
  }
  .row {
    display: flex;
    opacity: 0.5;
    .first-word {
      margin-right: 1.5rem;
    }
  }
  @media (max-width: 1050px) {
    .slogan {
      display: none;
    }
  }
  @media (max-width: 850px) {
    height: 70vh !important;
    h1 {
      font-size: 4rem !important;
    }
    button {
      padding: 0.5rem 1.2rem !important;
      font-size: 0.7rem !important;
    }
  }
  @media (max-width: 550px) {
    h1 {
      font-size: 3rem !important;
    }
    p {
      font-size: 0.85rem;
    }
    button {
      padding: 0.4rem 1rem !important;
      font-size: 0.6rem !important;
    }
  }
  @media (max-width: 410px) {
    h1 {
      font-size: 2.4rem !important;
    }
  }
  @media (max-width: 340px) {
    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      .first-word {
        margin-right: 0 !important;
      }
    }
  }
`
const Banner = ({ siteTitle }) => {
  const [view0, setView0] = useState(false)
  const [view1, setView1] = useState(false)
  const [view2, setView2] = useState(false)
  const [view3, setView3] = useState(false)
  const [view4, setView4] = useState(false)
  const array = [0, 1, 2, 3, 4, 5, 6, 7]

  useEffect(() => {
    array.forEach(function(obj, index) {
      setTimeout(function() {
        switch (index) {
          case 0:
            setView0(true)
            break
          case 1:
            setView1(true)
            break
          case 2:
            setView2(true)
            break
          case 3:
            setView3(true)
            break
          case 4:
            setView4(true)
            break
          default:
            break
        }
      }, 200 * (index + 1))
    })
  }, [])

  return (
    <Landing>
      <StyledImgDiv>
        <FadeDiv>
          <img src={bannerPic} />
        </FadeDiv>
      </StyledImgDiv>
      <section>
        {view3 && (
          <FadeDiv>
            <p className="slogan">
              Kadernícky salón CROP - Staráme sa o Vašu krásu
            </p>
          </FadeDiv>
        )}
        {false && (
          <FadeDiv>
            <Scroll type="id" element="services">
              <Button>Our services</Button>
            </Scroll>
          </FadeDiv>
        )}
      </section>
    </Landing>
  )
}

Banner.propTypes = {}

Banner.defaultProps = {}

export default Banner
