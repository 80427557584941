import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Heading from "./heading"
import icon_scissors from "../images/icon_scissors.png"
import icon_gel from "../images/icon_gel.png"
import icon_voucher from "../images/icon_voucher.png"
import icon_card from "../images/icon_card.png"
import crop from "../images/icon_house_crop.png"
import { colors, boxShadow } from "./globalStyle"
import styled, { keyframes } from "styled-components"
import GlobalHeading from "./globalHeading"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import { Button } from "@material-ui/core"

const StyledDiv = styled.div`
  .find-us {
    display: flex;
    width: 100%;
    margin: 2rem 0;

    img {
      max-width: 25vw;
      overflow: hidden;
      margin-right: 2rem;
      object-fit: contain;
    }

    p {
      color: ${colors.blueGrey};
      text-align: left;
      font-family: Maven Pro;
      font-weight: 500;
      font-size: 0.8rem;
      padding: 0;
      margin: 1rem 0;
    }
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 910px) {
  }
  @media (max-width: 768px) {
    .find-us {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-end;
      margin: 1rem 0;

      img {
        max-width: 10vw;
      }
    }
  }
  @media (max-width: 430px) {
  }
`

const OpenHours = ({ siteTitle }) => {
  const [expanded, setExpand] = useState(false)
  const [width, setWidth] = useState(0)
  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener("resize", updateWindowDimensions)

    return () => {
      window.removeEventListener("resize", updateWindowDimensions)
    }
  })

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
  }
  return (
    <StyledDiv>
      <section className="find-us">
        <img src={crop}></img>

        <div>
          <GlobalHeading secondary={"Kontakt"} />
          <p>
            Nám. gen. Štefánika 4 (budova pošty),<br></br>
            1.poschodie, č.dverí 122,<br></br>
            Stará Ľubovňa, 064 01<br></br>
            +421 902 875 707<br></br>
            info@kadernictvo-crop.sk
          </p>
        </div>
      </section>
    </StyledDiv>
  )
}

OpenHours.propTypes = {}

OpenHours.defaultProps = {}

export default OpenHours

/**
 * 
 *       <section className="text">
        <span>
          From the beautiful shores of Australia, <br />
          we present to you our company
          <button onClick={() => setExpand(!expanded)}>
            <p>...</p>
          </button>
        </span>

        {expanded && (
          <p class={expanded ? "animated fadeInDown " : "animated fadeInUp "}>
            Although we offer a wide range of products, services and solutions,
            what we are really offering to you is business growth, increased
            sales, improved visibility and lower operational costs. Being small
            in size guarantees our customers a unique and individual
            relationship with us. We at Acsella use the Agile framework to
            develop products, services and solutions. We periodically tough base
            with our customers during development process to ensure that our
            final product will not only meet but will also surpass customer
            expectations. After product delivery, our customers can also enjoy
            personalised support.
          </p>
        )}
      </section>
 */
