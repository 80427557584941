import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Heading from "./heading"
import icon_scissors from "../images/icon_scissors.png"
import icon_gel from "../images/icon_gel.png"
import icon_voucher from "../images/icon_voucher.png"
import icon_card from "../images/icon_card.png"
import crop from "../images/icon_house_crop.png"
import { colors, boxShadow } from "./globalStyle"
import styled, { keyframes } from "styled-components"
import GlobalHeading from "./globalHeading"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import { Button } from "@material-ui/core"

const StyledDiv = styled.div`
  .open-hours-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  table {
    width: 60%;
  }
  td {
    border-bottom: 0;
  }
  h3 {
    font-weight: 400;
    letter-spacing: 1.5px;
    line-height: 2rem;
    font-size: 1.5rem;
    font-family: Maven Pro;
    opacity: 0.1;
    text-transform: uppercase;
    text-align: right;
  }

  @media (max-width: 1024px) {
  }
  @media (max-width: 910px) {
  }
  @media (max-width: 768px) {
    h3 {
      display: none;
    }
    table {
      width: 100% !important;
      margin-bottom: 0 !important;
    }
  }
  @media (max-width: 430px) {
  }
`

const OpenHours = ({ siteTitle }) => {
  const [expanded, setExpand] = useState(false)
  const [width, setWidth] = useState(0)
  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener("resize", updateWindowDimensions)

    return () => {
      window.removeEventListener("resize", updateWindowDimensions)
    }
  })

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
  }
  return (
    <StyledDiv>
      <GlobalHeading primary={"Otváracie hodiny"} />
      <div className="open-hours-content">
        <table>
          <tr>
            <td>Pondelok</td>
            <td>8:00 - 15:00</td>
          </tr>
          <tr>
            <td>Utorok</td>
            <td>8:00 - 15:00</td>
          </tr>
          <tr>
            <td>Streda</td>
            <td>8:00 - 15:00</td>
          </tr>
          <tr>
            <td>Štvrtok</td>
            <td>8:00 - 15:00</td>
          </tr>
          <tr>
            <td>Piatok</td>
            <td>8:00 - 15:00</td>
          </tr>
        </table>
        <h3>
          “Príďte sa <br></br>k nám ostrihať <br></br>a oddýchnuť si!”
        </h3>
      </div>
    </StyledDiv>
  )
}

OpenHours.propTypes = {}

OpenHours.defaultProps = {}

export default OpenHours
