import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import img01 from "../images/new01.jpg"
import img02 from "../images/new02.jpg"
import img03 from "../images/new03.jpg"
import img04 from "../images/new04.jpg"
import img05 from "../images/new05.jpg"

import Carousel, { Modal, ModalGateway } from "react-images"
//import Lightbox from "react-images"
import { colors } from "./globalStyle"

import styled from "styled-components"

const StyledDiv = styled.div`
  section {
    display: flex;
    justify-content: space-between;
    img {
      width: 15vw;
      height: 15vw;
      object-fit: cover;
    }
  }
`

const Gallery = ({ primary, secondary }) => {
  const [modalIsOpen, setModal] = useState(false)
  const [currentImage, setCurrentImage] = useState(0)

  const images = [
    {
      source: img01,
    },
    {
      source: img02,
    },
    {
      source: img03,
    },
    {
      source: img04,
    },
    {
      source: img05,
    },
  ]
  const smallImages = [img01, img02, img03, img04, img05]

  const toggleModal = () => {
    setModal(!modalIsOpen)
  }

  const openLightbox = selectedImg => {
    setCurrentImage(selectedImg)
    setModal(true)
  }

  return (
    <StyledDiv>
      <section>
        {smallImages.map((img, i) => (
          <img src={img} onClick={() => openLightbox(i)}></img>
        ))}
      </section>
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={toggleModal}>
            <Carousel currentIndex={currentImage} views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </StyledDiv>
  )
}

Gallery.propTypes = {}

Gallery.defaultProps = {}

export default Gallery
