import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import GlobalHeading from "./globalHeading"

import { colors } from "./globalStyle"

import styled from "styled-components"
import Gallery from "./gallery"
import Slider from "./mobileSlider"

const StyledDiv = styled.div`
  p {
    color: ${colors.blueGrey};
    text-align: left;
    font-family: Maven Pro;
    font-size: 0.8rem;
    padding: 0;
    margin: 1rem 0;
  }
`

const PriceList = ({ primary, secondary }) => {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    updateWindowDimensions()
    window.addEventListener("resize", updateWindowDimensions)

    return () => {
      window.removeEventListener("resize", updateWindowDimensions)
    }
  })

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth)
  }
  return (
    <StyledDiv>
      <GlobalHeading primary={"Cenník"} />
      <p>Bližšie info po konzultácii v salóne</p>

      {width >= 620 && <Gallery />}
      {width < 620 && <Slider />}
    </StyledDiv>
  )
}

PriceList.propTypes = {}

PriceList.defaultProps = {}

export default PriceList
