import React from "react"
import { Link } from "gatsby"
import { hot } from "react-hot-loader/root"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/banner"

import Form from "../components/form"
import Footer from "../components/footer"
import AboutUs from "../components/aboutUs"
import PriceList from "../components/priceList"
import OpenHours from "../components/openHours"
import WhereWeAre from "../components/whereWeAre"
import styled, { ThemeProvider } from "styled-components"

const StyledMain = styled.main`
  padding: 0 7rem !important;
  max-width: 1900px;
  margin: 0 auto;
  .first-section {
    padding-top: 2rem;
  }

  @media (max-width: 910px) {
    padding: 0 5rem !important;
  }
  @media (max-width: 768px) {
    padding: 0 3rem !important;
  }
  @media (max-width: 430px) {
    padding: 0 2rem !important;
  }
`
const IndexPage = () => (
  <Layout>
    <SEO
      title="Kaderníctvo CROP"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <section id="banner">
      <Banner />
    </section>
    <StyledMain>
      <section id="about-us" className="first-section">
        <AboutUs />
      </section>
      <section id="price-list" className="first-section">
        <PriceList />
      </section>{" "}
      <section id="opening-hours" className="first-section">
        <OpenHours />
      </section>
      <section id="where-we-are" className="first-section">
        <WhereWeAre />
      </section>
      <section id="say hello" className="first-section">
        <Form />
      </section>
    </StyledMain>
    <Footer />
  </Layout>
)

export default hot(IndexPage)
